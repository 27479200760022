<template>
  <!--begin::Card header-->
  <div class="card-header border-0 bg-default">
    <!--begin::Card title-->
    <div class="card-title m-0 d-flex justify-content-between w-100">
      <!--begin::Navs-->
      <div class="d-flex flex-column w-100">
        <div class="row fs-6 w-100 mb-5 mt-5 text-white">
          <div class="fw-light mb-2">Tabulasi Data KTANisasi</div>
          <div class="row">
            <div class="col-md-5">
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <span>Target KTA:</span>
                <span> {{ Intl.NumberFormat().format(tabulasi.target) }}</span>
              </div>
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <span>KTA Terdaftar:</span>
                <span
                  >{{ Intl.NumberFormat().format(tabulasi.terdaftar) }}
                </span>
              </div>
            </div>
            <div class="col-md-1 d-none d-md-block"></div>
            <div class="col-md-5">
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <span>Sisa Target KTA:</span>
                <span> {{ Intl.NumberFormat().format(tabulasi.sisa) }}</span>
              </div>
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <span>Persentase Tercapai:</span>
                <span>
                  {{
                    Intl.NumberFormat().format(tabulasi.persentase.toFixed(0))
                  }}%</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex overflow-auto h-55px mb-5">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                to="/ktanisasi/caleg"
                class="nav-link text-active-white text-warning me-6"
                active-class="active"
              >
                Caleg
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/ktanisasi/dpc"
                active-class="active"
              >
                DPC
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--begin::Navs-->
    </div>
    <!--end::Card title-->
  </div>
  <!--end::Card header-->
  <!--begin::Card body-->
  <div class="card-body border-top p-9">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="fw-bolder">
          <th>Nama Caleg</th>
          <th>Dapil</th>
          <th>Jumlah KTA</th>
          <th>Aksi</th>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data" :key="i">
            <td>{{ item.candidate.name }}</td>
            <td>{{ item.kta_candidate.dapil }}</td>
            <td>{{ item.kta_candidate.count }}</td>
            <td>
              <router-link
                :to="{
                  name: 'ktanisasi.detail.caleg',
                  params: {
                    id: $CryptoJS.AES.encrypt(
                      item.kta_candidate.id.toString(),
                      'PKS.id'
                    ).toString(),
                    dataDetail: '',
                  },
                }"
              >
                <button class="rounded btn btn-sm btn-default">
                  Lihat data
                </button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
export default {
  setup() {
    setCurrentPageTitle("KTANisasi");
    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllKTACandidateDashboard");

    store.commit("SET_ACTIVE_MENU", "ktanisasi");

    const data = computed(() => store.state.KTACandidateDashboardModule.all);
    const tabulasi = ref({
      target: 0,
      terdaftar: 0,
      sisa: 0,
      persentase: 0,
    });

    watch(data, () => {
      if (data.value && data.value.length) {
        console.log(data.value);
        data.value.map((item) => {
          tabulasi.value.target += item.kta_candidate.target;
          tabulasi.value.terdaftar += item.kta_candidate.count;
        });
      }
      tabulasi.value.sisa = tabulasi.value.target - tabulasi.value.terdaftar;
      tabulasi.value.persentase =
        (tabulasi.value.terdaftar / tabulasi.value.target) * 100;
    });

    return {
      data,
      tabulasi,
    };
  },
};
</script>
